<template>
  <div class="">
    <el-form ref="queryForm" :model="queryParams" :inline="true">
      <el-form-item>
        <el-input
          v-model="queryParams.name"
          placeholder="请输入姓名"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.status"
          placeholder="请选择客服状态"
          @keyup.enter.native="handleQuery"
        >
          <el-option
            v-for="item in statusType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >搜索</el-button
        >
        <el-button
          @click="handleAddCustomer"
          type="primary"
          style="margin-left: 30px"
          >添加客服</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div
      class="merch-table scorr-roll"
      :style="`max-height: ` + mainHright + `px`"
    >
      <div>
        <el-table
          border
          :data="searchData"
          style="width: 100%"
          max-height="500"
          v-loading="loading"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column align="center" label="序号" width="70">
            <template slot-scope="scope">
              <span>{{ scope.row.index }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="姓名"
            prop="name"
          ></el-table-column>
          <el-table-column align="center" label="性别">
            <template slot-scope="{ row }">
              <span>{{ row.sex * 1 == 1 ? "男" : "女" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="联系电话"
            prop="mobile"
          ></el-table-column>
          <el-table-column align="center" label="状态" prop="status" key="6">
            <template slot-scope="scope">
              <div>
                {{ scope.row.status * 1 == 1 ? "启用" : "停用" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300" key="7">
            <template slot-scope="scope">
              <span
                v-if="scope.row.status * 1 != 1"
                class="btsed"
                @click="handleChange(scope.row)"
                >启用</span
              >
              <span v-else class="btsed" @click="handleChange(scope.row)"
                >停用</span
              >
              <span class="btsed" @click="handleEdit(scope.row)">编辑</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.perPage"
        @pagination="getList"
      />
    </div>
    <el-dialog width="500px" title="新建客服" :visible.sync="serviceVisible">
      <el-form
        ref="serviceForm"
        :model="serviceForm"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="serviceForm.name"
            placeholder="请输入管理员姓名"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="serviceForm.sex" :label="1">男</el-radio>
          <el-radio v-model="serviceForm.sex" :label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input
            v-model="serviceForm.mobile"
            placeholder="请输入联系电话"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="serviceVisible = false">取 消</el-button>
        <el-button v-if="isCreate" type="primary" @click="handleCreateService"
          >创 建</el-button
        >
        <el-button v-else type="primary" @click="handleSaveService"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
export default {
  data() {
    return {
      isCreate: true,
      serviceVisible: false,
      serviceForm: {
        name: "",
        sex: 1,
        mobile: "",
      },
      queryParams: {
        perPage: 10,
        page: 1,
        name: "",
        status: "",
      },
      loading: false,
      total: 0,
      searchData: [],
      statusType: [
        {
          name: "全部",
          id: 0,
        },
        {
          name: "启用",
          id: 1,
        },
        {
          name: "停用",
          id: 2,
        },
      ],
      rules: {
        name: [
          { required: true, message: "管理员姓名必填", trigger: "change" },
        ],
        sex: [{ required: true, message: "性别必填", trigger: "change" }],
        mobile: [
          { required: true, message: "联系电话必填", trigger: "change" },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/g,
            message: "请输入正确电话号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapActions([
      "getServiceList",
      "createCustomerService",
      "updateCustomerService",
      "customerServiceStatus",
    ]),
    ...commonIndex.mapState(["mainHright"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    async handleCreateService() {
      this.$refs.serviceForm.validate(async (valid) => {
        if (valid) {
          let res = await this.createCustomerService(this.serviceForm);
          if (res.code * 1 == 200) {
            this.$message({
              type: "success",
              message: res.message,
            });
          }
          this.serviceVisible = false;
          this.getList();
        }
      });
    },
    async handleSaveService() {
      this.$refs.serviceForm.validate(async (valid) => {
        if (valid) {
          let res = await this.updateCustomerService(this.serviceForm);
          if (res.code * 1 == 200) {
            this.$message({
              type: "success",
              message: res.message,
            });
          }
          this.serviceVisible = false;
          this.getList();
        }
      });
    },
    async getList() {
      this.loading = true;
      let res = await this.getServiceList(this.queryParams);
      res.data.data.forEach((v, i) => {
        let index = (res.data.currentPage - 1) * res.data.perPage + i + 1;
        this.$set(v, "index", index);
      });
      this.searchData = res.data.data;
      this.total = res.data.total;
      this.loading = false;
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    handleAddCustomer() {
      if (this.$refs.serviceForm !== undefined)
        this.$refs.serviceForm.resetFields();
      this.isCreate = true;
      Object.assign(this.serviceForm, this.$options.data().serviceForm);
      this.serviceVisible = true;
    },
    async handleChange(row) {
      let res = await this.customerServiceStatus({ id: row.id });
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.message,
        });
      }
      this.getList();
    },
    handleEdit(row) {
      this.isCreate = false;
      this.serviceForm.name = row.name;
      this.serviceForm.mobile = row.mobile;
      this.serviceForm.sex = row.sex;
      this.serviceForm.id = row.id;
      this.serviceVisible = true;
    },
    handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}
.el-form-item {
  margin-bottom: 10px;
}
.merch-table {
  margin-top: 1%;
  width: 100%;
  height: 77%;
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba($color: #2e3238, $alpha: 0.05);
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
</style>