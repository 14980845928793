<template>
  <!-- 添加商品 -->
  <div class="rela">
    <el-dialog
      v-if="additemClose"
      title="白名单列表"
      :visible.sync="additemClose"
      :close-on-click-modal="false"
      :before-close="additemCloseinded"
      width="1100px"
      custom-class="el-dialog-s"
      append-to-body
    >
      <div>
        <div class="onads abso">
          <span class="el-icon-warning"></span>
          白名单供应商的结算周期默认按白名单时效，非白名单供应商按统一时效
        </div>
        <div class="onadser abso">
          <el-button
            size="mini"
            @click="BatchSetCommission"
            class="green_button"
            >添加</el-button
          >
        </div>
      </div>
      <!-- 列表 -->
      <div>
        <el-table
          v-loading="loading"
          :data="searchList"
          :header-cell-style="{ background: '#F2F3F5' }"
          border
        >
          <el-table-column
            prop="id"
            label="序号"
            align="center"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="businessId"
            label="供应商编号"
            align="center"
          />
          <el-table-column prop="name" label="供应商名称" align="center" />
          <el-table-column prop="day" label="时效（天）" align="center" />
          <el-table-column prop="createTime" label="添加时间" align="center" />
          <el-table-column prop="operator" label="操作人" align="center" />
          <el-table-column prop="complaintContent" label="操作" align="center">
            <template slot-scope="{ row }">
              <div class="userBtn">
                <div @click="handleDelete(row)">移除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="footer">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.perPage"
          @pagination="getList"
        />
      </div>
      <div slot="footer">
        <el-button size="mini" @click="additemCloseinded">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 新增白名单 -->
    <el-dialog
      title="新增白名单"
      :close-on-click-modal="false"
      :visible.sync="dialogAdd"
      width="380px"
    >
      <div>
        <span style="margin-right: 10px">供应商</span>
        <el-select
          placeholder="请输入供应商名称/编号"
          filterable
          :filter-method="filterValue"
          v-model="Additem.businessId"
          style="width: 270px"
          @change="selectChange"
          @visible-change="selectChange"
          clearable
          v-el-select-lazyloading="lazyloading"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div style="margin-top: 20px">
        <span style="margin-right: 23px">时效</span>
        <el-input-number
          v-model="Additem.day"
          controls-position="right"
          :min="0"
        ></el-input-number>
        <span style="margin-left: 10px">天</span>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleAdd">保 存</el-button>
        <el-button @click="dialogAdd = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  name: "AddItem",
  data() {
    return {
      loading: false,
      additemClose: false,
      queryParams: {
        page: 1, // 页数
        perPage: 10, // 每页条数
      },
      stateType: "1", //判断是 退货时效、收货时效
      searchList: [], //列表数据
      total: 0,
      dialogAdd: false, //添加弹窗
      Additem: {
        day: 0, //时效（天）
        businessId: "", //添加供应商id
      },
      options: [], //供应商列表（下拉框）
      queryUser: {
        name: "", //单位名称
        currPage: 1, // 页码
        pageSize: 10, //每页大小
      },
    };
  },

  methods: {
    ...mapActions([
      "returnTimelineslist", //退货时效-白名单-列表
      "returnTimelinesadd", //退货时效-白名单-添加
      "returnTimelinesremove", //退货时效-白名单-移除
      "receiveTimelineslist", //收货时效-白名单-列表
      "receiveTimelinesadd", //收货时效-白名单-添加
      "receiveTimelinesremove", //收货时效-白名单-移除
    ]),
    ...merchantCenterList.mapActions(["postbusinessList"]),
    init() {
      this.additemClose = true;
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    getList() {
      this.loading = true;
      if (this.stateType == "1") {
        this.returnTimelineslist(this.queryParams).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.searchList = res.data.data;
            this.total = res.data.total;
          }
        });
      } else {
        this.receiveTimelineslist(this.queryParams).then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.searchList = res.data.data;
            this.total = res.data.total;
          }
        });
      }
    },
    /** 移除 */
    handleDelete(row) {
      this.$confirm(`确定要移除供应商白名单【${row.name}】?`, "移除提醒", {
        closeOnClickModal: false,
        confirmButtonText: "移除",
        cancelButtonText: "取消",
        showClose: false,
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (this.stateType == "1") {
            this.returnTimelinesremove({
              id: row.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success("移除");
                if (this.queryParams.page > 1 && this.searchList.length == 1) {
                  this.queryParams.page--;
                }
                this.getList();
              }
            });
          } else {
            this.receiveTimelinesremove({
              id: row.id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success("移除");
                if (this.queryParams.page > 1 && this.searchList.length == 1) {
                  this.queryParams.page--;
                }
                this.getList();
              }
            });
          }
        })
        .catch(function () {});
    },
    /** 添加弹窗 */
    async BatchSetCommission() {
      this.dialogAdd = true;
      this.options = [];
      Object.assign(this.queryUser, this.$options.data().queryUser);
      Object.assign(this.Additem, this.$options.data().Additem);
      await this.getUserList();
    },
    // 获取供应商列表（下拉框）
    getUserList() {
      this.postbusinessList(this.queryUser).then((res) => {
        if (res.code == "000000") {
          if (this.options.length < res.content.total) {
            this.options = JSON.parse(
              JSON.stringify([...this.options, ...res.content.list])
            );
          }
        }
      });
    },
    //下拉框搜索
    filterValue(value) {
      this.options = [];
      this.queryUser.currPage = 1;
      this.queryUser.name = value;
      this.getUserList();
    },
    // 下拉框懒加载
    lazyloading() {
      this.queryUser.currPage++;
      this.getUserList();
    },
    //选择
    selectChange(id) {
      if (id == "") {
        this.options = [];
        this.queryUser.currPage = 1;
        this.queryUser.name = "";
        this.getUserList();
      } else {
        this.options.forEach((value) => {
          if (value.id == id) {
            this.Additem.businessId = id;
          }
        });
      }
    },
    // 添加白名单
    async handleAdd() {
      if (this.Additem.businessId == "")
        return this.$message.error("请选择供应商");
      if (this.stateType == "1") {
        var res = await this.returnTimelinesadd(this.Additem);
      } else {
        var res = await this.receiveTimelinesadd(this.Additem);
      }
      if (res.code == 200) {
        this.dialogAdd = false;
        this.$message.success("添加成功");
        this.getList();
      }
    },
    additemCloseinded() {
      this.additemClose = false;
      this.searchList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.onads {
  z-index: 1000;
  color: red;
  left: 30px;
  top: 60px;
}
.onadser {
  z-index: 1000;
  right: 20px;
  top: 50px;
}
.abso {
  position: absolute;
}
.rela {
  position: relative;
}
.green_button {
  color: #fff;
  background: #06b7ae;
}
</style>
